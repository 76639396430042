import defaultTheme from "./default";
import VARIABLES from "../global/variables";

export default {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    color: [
      VARIABLES.COLORS.orange, // 0: Orange
      VARIABLES.COLORS.purple, // 1: Purple
      VARIABLES.COLORS.pink, // 2: Pink
      VARIABLES.COLORS.limeGreen, // 3: LimeGreen
      VARIABLES.COLORS.blueShade, // 4: BlueShade
      VARIABLES.COLORS.yellow, // 5: Yellow
      VARIABLES.COLORS.darkYellowFive, // 6: Yellow Darken 5%
      VARIABLES.COLORS.facebook, // 7: Facebook
      VARIABLES.COLORS.darkFacebookFive, // 8: Facebook Darken 5%
      VARIABLES.COLORS.googlePlus, // 9: Google Plus
      VARIABLES.COLORS.darkGooglePlusFive, // 10: Google Plus Darken 5%
      VARIABLES.COLORS.lightOrangeOne, // 11: Auth0
      VARIABLES.COLORS.lightOrangeTwo, // 12: Auth0
      VARIABLES.COLORS.lightRedOne, // 13: themeColor--AlizarinCrimson
      VARIABLES.COLORS.nimbleGreen, // 14: light green
      VARIABLES.COLORS.nimbleGreen, // 15: themeColor--- Dark green
      VARIABLES.COLORS.white, // 16: white,
      VARIABLES.COLORS.lightGrayOne, // 17: light gray,
      VARIABLES.COLORS.lightRedTwo, // 18: light red,
      VARIABLES.COLORS.lightGreenTwo, // 19: themeColor-- extra light green,
      VARIABLES.COLORS.lightGreenThree, // 20: extra light green,
      VARIABLES.COLORS.black, // 21: black,
      VARIABLES.COLORS.lightYellowOne, // 22: yellow,
      VARIABLES.COLORS.lightYellowTwo, // 23: light yellow
      VARIABLES.COLORS.lightBlueThree, // 24 blue upload button
      VARIABLES.COLORS.lightGreenEight, // 25 extra light screen
      VARIABLES.COLORS.lightBlueThree, // 26 blue upload button
      VARIABLES.COLORS.yesLoansLightBlue, // 27 YesLoansBlue at 10%
      VARIABLES.COLORS.nimbleGreen, // 28: tooltip icon fill
      VARIABLES.COLORS.nimbleGreen, // 29
      VARIABLES.COLORS.white, // 30
      VARIABLES.COLORS.yesLoansLighterBlue,  // 31
      VARIABLES.COLORS.jackrabbitRed, // 32
      VARIABLES.COLORS.salmon,  // 33
      VARIABLES.COLORS.yesLoansRed, // 34 
      VARIABLES.COLORS.nimbleSlate // 35 section heading
    ],
    grayscale: [
      VARIABLES.COLORS.lightGrayTwenty, // 0: GreyShade
      VARIABLES.COLORS.lightGrayTwo, // 1: GreyDark
      VARIABLES.COLORS.lightGrayFour, // 2: Grey
      VARIABLES.COLORS.lightGrayFive, // 3: GreyAlt
      VARIABLES.COLORS.lightGraySix, // 4: GreyLight
      VARIABLES.COLORS.lightGraySeven, // 5: DarkWhite
      VARIABLES.COLORS.lightGrayEight, // 6: DarkerWhite
      VARIABLES.COLORS.lightGrayNine, // 7: #fff Darken 1%
      VARIABLES.COLORS.lightGrayTen, // 8:
      VARIABLES.COLORS.lightGrayEleven, // 9:
      VARIABLES.COLORS.lightGrayTwelve, // 10:
      VARIABLES.COLORS.lightGrayTherteen, // 11: today-highlight-bg
      VARIABLES.COLORS.lightFourteenGray, // 12: light gray with less opacity
      VARIABLES.COLORS.lightGrayFifteen, // 13: extra light gray with green
      VARIABLES.COLORS.lightGraySixteen, // 14: extra light gray with green
      VARIABLES.COLORS.lightGraySeventeen, // 15: gray with red
      VARIABLES.COLORS.lightGrayTwentyOne, // 16: extra light gray with blue
      VARIABLES.COLORS.gray, // 17: GreyDarker
      VARIABLES.COLORS.darkGrayOne, // 18: Grey
      VARIABLES.COLORS.darkGrayTwo // 19: Grey sade
    ],
    header: [
      VARIABLES.COLORS.jackrabbitRed,
    ],
    breadCrumHeader: [
      VARIABLES.COLORS.jackrabbitRed, // 0: active text color
    ],
    accordian: [
      VARIABLES.COLORS.jackrabbitRed, // 0: Head bar background one
      VARIABLES.COLORS.white, // 1: Head bar text Color
      VARIABLES.COLORS.jackrabbitRed, // 2: panel background
      VARIABLES.COLORS.white, // 3: panel text color
      VARIABLES.COLORS.jackrabbitRed, // 4: Head bar background two
      VARIABLES.COLORS.darkGrayFourteen // 5: List item disc icon
    ],
    formInputElement: [
      VARIABLES.COLORS.nimbleSlate, // 0: Label
      VARIABLES.COLORS.nimbleSlate, // 1: border
      VARIABLES.COLORS.nimbleSlateLight, // 2: place holder
      VARIABLES.COLORS.white, // 3: tooltip
      VARIABLES.COLORS.nimbleGreen, // 4: optional label
      VARIABLES.COLORS.white, // 5: input background
      VARIABLES.COLORS.nimbleSlate, // 6: select input border
      VARIABLES.COLORS.nimbleGreen, // 7: dollarsign
      VARIABLES.COLORS.black, // 8: input text color
    ],
    footer: [
      VARIABLES.COLORS.jackrabbitRed, // 0: border top
      VARIABLES.COLORS.jackrabbitRed, // 0: BackGround
      VARIABLES.COLORS.white, // 1: Text
    ],
    link:[
      VARIABLES.COLORS.nimbleGreen, // 0: themeLinkTextColor
      VARIABLES.COLORS.nimbleGreen, // 1: themeLinkTextColor-onHover
    ],
    checkMark: [
      VARIABLES.COLORS.nimbleGreen,
      VARIABLES.COLORS.nimbleGreen,
      VARIABLES.COLORS.nimbleLightGreen,
    ],
    docIcon: [
      VARIABLES.COLORS.nimbleGreen,
    ],
    bankStatementsPrompt: [
      VARIABLES.COLORS.lightGrayTwentyOne,
    ],
    modal: [
      VARIABLES.COLORS.jackrabbitRed,
      VARIABLES.COLORS.nimbleGreen,
      VARIABLES.COLORS.lightFourteenGrayOpaque,
      VARIABLES.COLORS.jackrabbitRed,
    ],
    "button.default": [
      VARIABLES.COLORS.nimbleGreen,
      VARIABLES.COLORS.white,
    ],
    "button.header": [
      VARIABLES.COLORS.white,
      VARIABLES.COLORS.nimbleGreen,
    ],
    progressionBar: [
      VARIABLES.COLORS.nimbleLightGrey,
      VARIABLES.COLORS.nimbleYellow
    ],
    thankYou: [
      VARIABLES.COLORS.nimbleGreen,
      VARIABLES.COLORS.jackrabbitRed,
      VARIABLES.COLORS.nimbleGreen,
    ],
    fieldWell: [
      VARIABLES.COLORS.nimbleLightGreen,
      VARIABLES.COLORS.lightGrayTwentyOne,
    ],
    loanLimit: [
      VARIABLES.COLORS.nimbleGreen
    ],
    alertTwo: [
      VARIABLES.COLORS.nimbleGreen
    ],
    input: [
      VARIABLES.COLORS.nimbleGreen
    ],
    tracking: [
      VARIABLES.COLORS.trackingGray,
      VARIABLES.COLORS.jackrabbitRed,
      VARIABLES.COLORS.jackrabbitRed,
      VARIABLES.COLORS.lightRedFour,
      VARIABLES.COLORS.jackrabbitRed,
      VARIABLES.COLORS.jackrabbitRed,
    ]
  },
  fonts: {
    primary: "'SharpGroteskBook', sans-serif",
    headingWeight: '600',
    headingCase: 'none'
  }
};
