import { PUBLIC_ROUTE } from "../route.constants";

export const APP_STATE = {
  LOADING: "loading",
  READY: "ready",
  ERROR: "error",
};

export const TRUE_FALSE_VALUES = {
  TRUE: "true",
  FALSE: "false"
};

export const THIS_APP = "FGF App Form";

export const AGREE_NOT_AGREE_VALUES = {
  AGREE: "agree",
  NOT_AGREE: "notAgree"
};

export const STATES = {
  ACT: "ACT",
  NSW: "NSW",
  NT: "NT",
  QLD: "QLD",
  SA: "SA",
  TAS: "TAS",
  VIC: "VIC",
  WA: "WA",
};

/*
  Outlines the step value, label and path for each of the form steps that require the user to fill out information.
  
  how is step percentage calculated:
  first step % is 12.5
  thankyou is 100%
  therefore page before thankyou is 100-12.5 === 87.5
  next we have 3 sections between first page and thankyou (2,3,4) therefore 
  (100 - 12.5 - 12.5) / 3 === 25
  therefore step 2: 12.5 + 25
  step 3: step 2 + 25
  step 4: step 3 + 25 (87.5%!!!)
*/
export const STEPS = [
  {
    step: 1,
    label: "Start",
    percentComplete: 12.5,
    path: PUBLIC_ROUTE.LANDING
  },
  {
    step: 2,
    label: "Living Situation",
    percentComplete: 37.5,
    path: PUBLIC_ROUTE.LANDING + PUBLIC_ROUTE.LIVING_SITUATION
  },
  {
    step: 3,
    label: "Livelihood Income",
    percentComplete: 62.5,
    path: PUBLIC_ROUTE.LANDING + PUBLIC_ROUTE.LIVELIHOOD_INCOME
  },
  {
    step: 4,
    label: "Bank Statement",
    percentComplete: 87.5,
    path: PUBLIC_ROUTE.LANDING + PUBLIC_ROUTE.BANK_STATEMENT_PAGE
  },
];

export const STEPS_TOTAL = STEPS.length;

const createVerifyStep = (stepName, label) => ({
  stepName,
  label,
  path: "/" + stepName,
  fullPath: PUBLIC_ROUTE.VERIFY + "/" + stepName,
});

export const VERIFY_STEPS = {
  identity: createVerifyStep("id-validation", "Identity"),
  twoPlusSacc: createVerifyStep("two-plus-sacc-accounts", "Two Plus SACC Accounts"),
  decline: createVerifyStep("declined", "Declined"),
  noWages: createVerifyStep("no-wages", "No Wages"),
};

export const TRACKING_STATUS = {
  IN_SCHEDULE: "/in-schedule",
  IN_ASSESSMENT: "/in-assessment",
  DECLINED: "/declined",
  APPROVED_ESIGN: "/approved-esign",
  FUNDED: "/funded",
  CANCELLED: "/cancelled",
  EXPIRED: "/expired",
  ERROR: "/error",
}

export const VERIFY_STEPS_ARRAY = Object.values(VERIFY_STEPS);

export const VERIFY_ROUTES = {
  THANK_YOU: "thank-you",
};

export const IDENTIFICATION_TYPES_VALUES = {
  DRIVERS_LICENCE: "DriversLicence",
  MEDICARE: "MedicareCard"
};

export const LOAN_STATUS = {
  DECLINED: "Declined",
  CREATED: "Created",
  DECLINED_IN_PROGRESS: "DeclinedInProgress",
  NOT_RETRIEVABLE: "NotRetrievable",
  NOT_FOUND: "NotFound",
  INVALID: "Invalid"
};

export const LIVING_SITUATIONS = {
  LIVE_WITH_PARTNER: "I live with my partner",
  LIVE_WITH_MY_FRIEND: "I live with friend(s)"
};

export const MARITAL_STATUS = {
  SINGLE: "Single",
  MARRIED: "Married",
  LIVING_TOGETHER: "Living together",
  SEPARATED: "Separated",
  DIVORCED: "Divorced",
  WIDOWED: "Widowed"
};

export const PRODUCT_TYPE = {
  PERSONAL_LOAN: "personalLoan",
  CAR_LOAN: "carLoan",
  LINE_OF_CREDIT: "lineOfCredit",
};

export const RESIDENTIAL_STATUS = {
  RENTING: "Renting",
  MORTGAGE: "Mortgage",
  BOARDING: "Boarding",
  HOMEOWNER: "Homeowner",
};

export const FIELD_NAMES = {
  BANK_STATEMENT_REFERRAL_CODE: "bankStatementReferralCode",
  BANK_STATEMENT_REFERRAL_CODE_SKIP: "bankStatementReferralCodeSkip",
  LOAN_REASON: "loanReason",
  LOAN_AMOUNT: "loanAmount",
};

// note: this is for known constants, the actual list of values is a remote API resource
export const LOAN_REASONS = {
  VEHICLE_PURCHASE: "Vehicle Purchase",
};

export const EXTERNAL_ID_PREFIX = "loanapplication-";

export const DEFAULT_PAPER_PADDING = 20;

export const FORM_ID = "application-form";
export const FORM_ID_ESIGN = "application-form-esign";

export const isPositionStickySupported = (() => {
  const falseFunc = () => false;
  return 'CSS' in window && (CSS.supports || falseFunc)('position', 'sticky');
})();

export const ESIGN_DOCUMENT_TYPE = {
  CONTRACT: {type: "CON", name: "Contract"},
  PAYMENT_SCHEDULE: {type: "SCH.REPWEB", name: "Payment Schedule"}
}

export const smsName = "sms";

export const acceptContractAndSchedule = "acceptContractAndSchedule";

export const illionBrokerFlowIdFieldName = "illionBrokerFlowDocumentId";

export const brandFieldName = "brand";