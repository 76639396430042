import defaultTheme from "./default";
import VARIABLES from "../global/variables";

export default {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    color: [
      VARIABLES.COLORS.orange, // 0: Orange
      VARIABLES.COLORS.purple, // 1: Purple
      VARIABLES.COLORS.pink, // 2: Pink
      VARIABLES.COLORS.limeGreen, // 3: LimeGreen
      VARIABLES.COLORS.blueShade, // 4: BlueShade
      VARIABLES.COLORS.yellow, // 5: Yellow
      VARIABLES.COLORS.darkYellowFive, // 6: Yellow Darken 5%
      VARIABLES.COLORS.facebook, // 7: Facebook
      VARIABLES.COLORS.darkFacebookFive, // 8: Facebook Darken 5%
      VARIABLES.COLORS.googlePlus, // 9: Google Plus
      VARIABLES.COLORS.darkGooglePlusFive, // 10: Google Plus Darken 5%
      VARIABLES.COLORS.lightOrangeOne, // 11: Auth0
      VARIABLES.COLORS.lightOrangeTwo, // 12: Auth0
      VARIABLES.COLORS.lightRedOne, // 13: themeColor--AlizarinCrimson
      VARIABLES.COLORS.overflowBlack, // 14: light green
      VARIABLES.COLORS.overflowBlack, // 15: themeColor--- Dark green
      VARIABLES.COLORS.white, // 16: white,
      VARIABLES.COLORS.lightGrayOne, // 17: light gray,
      VARIABLES.COLORS.lightRedTwo, // 18: light red,
      VARIABLES.COLORS.lightGreenTwo, // 19: themeColor-- extra light green,
      VARIABLES.COLORS.lightGreenThree, // 20: extra light green,
      VARIABLES.COLORS.black, // 21: black,
      VARIABLES.COLORS.lightYellowOne, // 22: yellow,
      VARIABLES.COLORS.lightYellowTwo, // 23: light yellow
      VARIABLES.COLORS.lightBlueThree, // 24 blue upload button
      VARIABLES.COLORS.lightGreenEight, // 25 extra light screen
      VARIABLES.COLORS.lightBlueThree, // 26 blue upload button
      VARIABLES.COLORS.overflowBlack, // 27
      VARIABLES.COLORS.overflowBlack, // 28: tooltip icon fill
      VARIABLES.COLORS.overflowBlack, // 29
      VARIABLES.COLORS.overflowWhite, // 30
      VARIABLES.COLORS.overflowGreen,  // 31
      VARIABLES.COLORS.overflowBlack,  // 32
      VARIABLES.COLORS.salmon,  // 33
      VARIABLES.COLORS.overflowGreen, // 34
      VARIABLES.COLORS.overflowBlack // 35 section heading
    ],
    header: [
      VARIABLES.COLORS.white, // 0: white
      VARIABLES.COLORS.white, // 1: white
      VARIABLES.COLORS.overflowBlack,
      VARIABLES.COLORS.overflowBlack
    ],
    breadCrumHeader: [
      VARIABLES.COLORS.overflowGreen, // 0: active text color
    ],
    accordian: [
      VARIABLES.COLORS.overflowGrey, // 0: Head bar background one
      VARIABLES.COLORS.white, // 1: Head bar text Color
      VARIABLES.COLORS.overflowGrey, // 2: panel background
      VARIABLES.COLORS.white, // 3: panel text color
      VARIABLES.COLORS.overflowGrey, // 4: Head bar background two
      VARIABLES.COLORS.darkGrayFourteen // 5: List item disc icon
    ],
    formInputElement: [
      VARIABLES.COLORS.black, // 0: Label
      VARIABLES.COLORS.overflowBlack, // 1: border
      VARIABLES.COLORS.overflowBlack, // 2: place holder
      VARIABLES.COLORS.white, // 3: tooltip
      VARIABLES.COLORS.overflowBlack, // 4: optional label
      VARIABLES.COLORS.white, // 5: input background
      VARIABLES.COLORS.overflowBlack, // 6: select input border
      VARIABLES.COLORS.overflowBlack, // 7: dollarsign
      VARIABLES.COLORS.black, // 8: input text color
    ],
    footer: [
      VARIABLES.COLORS.white, // 0: border top
      VARIABLES.COLORS.white, // 0: BackGround
      VARIABLES.COLORS.black, // 1: Text
    ],
    link:[
      VARIABLES.COLORS.overflowBlack, // 0: themeLinkTextColor
      VARIABLES.COLORS.overflowGreen, // 1: themeLinkTextColor-onHover
    ],
    checkMark: [
      VARIABLES.COLORS.overflowGreen,
      VARIABLES.COLORS.white,
      VARIABLES.COLORS.overflowGreen,
    ],
    docIcon: [
      VARIABLES.COLORS.overflowGreen,
    ],
    bankStatementsPrompt: [
      VARIABLES.COLORS.overflowGrey,
    ],
    modal: [
      VARIABLES.COLORS.overflowBlack,
      VARIABLES.COLORS.overflowBlack,
      VARIABLES.COLORS.overflowGrey,
      VARIABLES.COLORS.overflowBlack,
    ],
    "button.default": [
      VARIABLES.COLORS.overflowGreen,
      VARIABLES.COLORS.white,
    ],
    "button.header": [
      VARIABLES.COLORS.white,
      VARIABLES.COLORS.overflowBlack,
    ],
    progressionBar: [
      VARIABLES.COLORS.overflowGrey,
      VARIABLES.COLORS.overflowBlack
    ],
    thankYou: [
      VARIABLES.COLORS.overflowBlack,
      VARIABLES.COLORS.overflowBlack,
      VARIABLES.COLORS.overflowGrey,
    ],
    fieldWell: [
      VARIABLES.COLORS.overflowGrey,
      VARIABLES.COLORS.overflowGrey,
    ],
    loanLimit: [
      VARIABLES.COLORS.overflowBlack,
    ],
    alertTwo: [
      VARIABLES.COLORS.overflowBlack
    ],
    input: [
      VARIABLES.COLORS.overflowBlack
    ],
    tracking: [
      VARIABLES.COLORS.trackingGray,
      VARIABLES.COLORS.overflowGrey,
      VARIABLES.COLORS.overflowBlack,
      VARIABLES.COLORS.lightRedFour,
      VARIABLES.COLORS.overflowBlack,
      VARIABLES.COLORS.overflowBlack,
    ],
  }
};
