import { createGlobalStyle } from "styled-components";
import { palette, font } from "styled-theme";
import NimbleFont from '../assets/fonts/SharpGroteskBook20-Regular.woff2';

const GlobalStyles = createGlobalStyle`
@font-face {
    font-family: "SharpGroteskBook";
    src: url(${NimbleFont}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette("primary", 0)};
    color: ${palette("color", 16)};
  }
}
*:focus{
  outline: none;
}
/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
  font-size: 15px;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
select,
span,
div,
html,
body,
html a {
  margin: 0;
  font-family: ${font("primary")};
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

#root {
  background-color: #fff;
  height: 100%;
  min-height: 100%;
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.page-subheading{
  max-width: 580px;
  margin: 10px auto;
}

a {
  word-break: break-all
}


select, 
input{
  font-size: 15px;
}

// // comment/uncomment to disable/enable scroll lock on modal open
// .ReactModal__Body--open {
//   overflow: hidden;
// }
`;

export default GlobalStyles;
